import React from "react"
import './DynamicWarmupSubSection.css';

const DynamicWarmupSubSection = ({ subSection, randomNumber = 0 }) => {
  return (
    <>
      <div className="DynamicWarmupSubSection-title">
        {subSection.title}
      </div>
      <div className="DynamicWarmupSubSection-content">
        {subSection.options[randomNumber]}
      </div>
    </>
  )
}

export default DynamicWarmupSubSection
