import React, { useState, useEffect } from "react";
import DynamicWarmupSubSection from "./DynamicWarmupSubSection";
import "./DynamicWarmupSection.css";

const DynamicWarmupSection = ({ section }) => {

  const [randomSeed, setRandomSeed] = useState(0);
  const [pageLoaded, setPageLoaded] = useState(false)
  useEffect(() => {
    setPageLoaded(true);
  }, [])

  return (
    <div className="DynamicWarmupSection-container">
      { pageLoaded ?
        <>
          {generateSection(section, randomSeed)}
          {generateDynamicButton(section, setRandomSeed)}
        </> : null
      }
    </div>
  )
}

const generateDynamicButton = (section, setRandomSeed) => {
  if (section.button) {
    return <button className="DynamicWarmupSection-generator-button" onClick={() => {
      setRandomSeed(Math.random());
    }}>{section.button}</button>
  }
}

const generateSection = (section, randomSeed) => {

  let sameRandomNumber = null;
  // If all subsections should align
  if (section.lockstep) {
    sameRandomNumber = randomSeed - randomSeed + Math.floor(Math.random() * section.sub_sections[0].options.length)
  };
  return section.sub_sections.map((subSection, i) => {
    const randomNumber = randomSeed - randomSeed + Math.floor(Math.random() * subSection.options.length);
    return <DynamicWarmupSubSection
              key={i}
              subSection={subSection}
              randomNumber={sameRandomNumber != null ? sameRandomNumber : randomNumber}
            />;
  });
}

export default DynamicWarmupSection
