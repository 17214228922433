import React from "react";
import DynamicWarmupSection from "./DynamicWarmupSection";
import OrderSection from "./OrderSection";
import "./WarmupSection.css";

const WarmupSection = ({ section }) => {
  return (
    <div className="WarmupSection-container">
      {generateSection(section)}
    </div>
  )
}

const generateSection = (section) => {
  switch(section.type) {
    case "dynamic_section":
      return <DynamicWarmupSection section={section} />;
    case "order_section":
      return <div className="WarmupSection-standard"><OrderSection section={section} /></div>;
    default:
      return <div className="WarmupSection-standard">{section}</div>;
  }
}

export default WarmupSection
