import React from "react"
const ORDER_SLUG = "order_slug"
const ORDERS = [
  "clockwise order (or, if anyone is remote, alphabetical order by participants' first names)",
  "alphabetical order by participants' last names",
]

const OrderSection = ({ section }) => {
  return (
    <>
      {section.text.replace(ORDER_SLUG, ORDERS[Math.floor(Math.random() * ORDERS.length)])}
    </>
  )
}

export default OrderSection
