import React, { useState } from "react";
import DynamicWarmupSection from "./DynamicWarmupSection";
import OrderSection from "./OrderSection";
import "./EnjoyMeetingSection.css";
import SuccessText from "../SuccessText";
import RandomWarmupButton from "./RandomWarmupButton"
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const ADD_FEEDBACK = gql`
  mutation AddFeedback($feedbackId: ID, $didLike: Boolean!, $warmupId: ID, $additionalFeedback: String, $email: String) {
    addFeedback(input: {feedbackId: $feedbackId, didLike: $didLike, warmupId: $warmupId, additionalFeedback: $additionalFeedback, email: $email}) {
      feedback {
        id
      }
    }
  }
`;

const EnjoyMeetingSection = ({ warmupId }) => {
  const [didLike, setDidLike] = useState(null);
  const [feedbackId, setFeedbackId] = useState(null);
  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [submittedFeedbackDetails, setSubmittedFeedbackDetails] = useState(false);
  const [email, setEmail] = useState("");
  const [addFeedback, { loading: mutationLoading, error: mutationError, data }] = useMutation(ADD_FEEDBACK, {
    onCompleted: (data) => {
      if (data.addFeedback && data.addFeedback.feedback) {
        setFeedbackId(data.addFeedback.feedback.id)
      };
    }
  });

  const submitFeedback = (didLike, warmupId, email, feedbackId, additionalFeedback) => {
    addFeedback({variables: {didLike: didLike, warmupId: warmupId, email: email, feedbackId: feedbackId, additionalFeedback: additionalFeedback}});
  }

  let surveyDetails = null;
  let survey = null;

  if (didLike !== null) {
    surveyDetails = (
      <div className="EnjoyMeetingSection-survey-bottom">
        <textarea value={additionalFeedback} onChange={e => setAdditionalFeedback(e.target.value)} className="EnjoyMeetingSection-feedback-text-area" placeholder="Any additional feedback?"></textarea>
        <input value={email} onChange={e => setEmail(e.target.value)} className="EnjoyMeetingSection-feedback-input" placeholder="Your email (optional)" />
        <button
          className="EnjoyMeetingSection-feedback-submit"
          onClick={() => {
            setSubmittedFeedbackDetails(true);
            submitFeedback(didLike, warmupId, email, feedbackId, additionalFeedback)
          }}
        >
          Submit
        </button>
      </div>
    )
  }

  if (!submittedFeedbackDetails) {
    survey = (
      <div className="EnjoyMeetingSection-survey">
        <form className="EnjoyMeetingSection-feedback-container" onSubmit={(e) => {
          e.preventDefault();
        }}>
          <div className="EnjoyMeetingSection-survey-top">
            Did you like this warm up?
            <label className={`EnjoyMeetingSection-yes-no ${didLike === true ? 'active' : null}`}>
              <div className="EnjoyMeetingSection-text">Yes</div>
              <input type="radio" name="did_like" onChange={() => {
                submitFeedback(true, warmupId, email, feedbackId, additionalFeedback)
                setDidLike(true);
              }} checked={didLike === true} value={true} />
            </label>
            <label className={`EnjoyMeetingSection-yes-no ${didLike === false ? 'active' : null}`}>
              <div className="EnjoyMeetingSection-text">No</div>
              <input type="radio" name="did_like" onChange={() => {
                submitFeedback(false, warmupId, email, feedbackId, additionalFeedback)
                setDidLike(false);
              }} checked={didLike === false} value={false} />
            </label>
          </div>
          {surveyDetails}
        </form>
      </div>
    );
  } else {
    survey = (
      <div className="EnjoyMeetingSection-thank-you"><SuccessText text="Thank you for your feedback!"/></div>
    )
  }

  return (
    <div className="EnjoyMeetingSection-container">
      <div><i>Enjoy your meeting!</i></div>
      {survey}
    </div>
  )
}

export default EnjoyMeetingSection
