import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import SEO from "../seo"
import WarmupSection from "./WarmupSection"
import Layout from "../layout"
import "./Warmup.css"
import WeirdPics from "../splash/WeirdPics"
import EnjoyMeetingSection from "./EnjoyMeetingSection";
import RandomWarmupButton from "./RandomWarmupButton";

const Warmup = ({ pageContext, location }) => {

  return (
    <Layout subtleButton>
      <SEO title={pageContext.title} />
      <div className="Warmup-container-outer">
        <div className="Warmup-padder Warmup-padder-topline">

        </div>
        <div className="Warmup-container">
          <div className="Warmup-title">
            <h1>{pageContext.title}</h1>
          </div>
          <div className="Warmup-steps">
            {
              pageContext.sections.map((section, i) => {
                let icon = null;
                let happy = null;
                if (i > 0) {
                  icon = (
                    <div className="Warmup-down-arrow">
                      <FontAwesomeIcon icon="chevron-down" />
                    </div>
                  );
                }
                if (pageContext.sections.length - 1 === i) {
                  happy = (
                    <div className="Warmup-down-arrow">
                      <FontAwesomeIcon icon="smile" />
                    </div>
                  )
                }
                return (
                  <div key={i}>
                    {icon}
                    <WarmupSection key={i} section={section} />
                  </div>
                )
              })
            }
            <div className="Warmup-down-arrow">
              <FontAwesomeIcon icon={faSmile} />
            </div>
            <EnjoyMeetingSection warmupId={pageContext.id}/>
          </div>
          {location && location.state ? location.state.fromFeed : null}
        </div>
        <div className="Warmup-padder">

        </div>
      </div>
      <WeirdPics />
    </Layout>
  )
}

export default Warmup
